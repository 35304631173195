import { JSX, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import SkapaToast, { ToastProps as SkapaToastProps } from '../components/Skapa/Toast/Toast';

export const ToastContext = createContext(
  {} as {
    set: Function;
  },
);

interface ToastProviderProps {
  children: JSX.Element;
}
export const ToastProvider = (props: ToastProviderProps) => {
  const { children } = props;
  const [inDOM, setInDOM] = useState(false);
  const [activeToast, setActiveToast] = useState<SkapaToastProps>({ text: '' });

  const set = useCallback((toast: SkapaToastProps) => {
    setActiveToast({ ...toast, isOpen: true });
    setInDOM(true);
  }, []);
  const close = () => setActiveToast({ ...activeToast, isOpen: false });

  useEffect(() => {
    if (activeToast.isOpen) {
      const timer = setTimeout(() => setActiveToast({ ...activeToast, isOpen: false }), 5000);
      return () => clearTimeout(timer);
    }
  }, [activeToast]);

  return (
    <ToastContext.Provider value={useMemo(() => ({ set }), [set])}>
      {inDOM &&
        createPortal(
          <SkapaToast
            text={activeToast.text}
            actionButtonText={activeToast.actionButtonText}
            ariaLabelCloseBtn={activeToast.ariaLabelCloseBtn}
            isOpen={activeToast.isOpen}
            onCloseRequest={close}
            actionClick={() => {
              close();
              activeToast.actionClick?.();
            }}
            onTransitionEnd={() => setInDOM(false)}
          />,
          document.body,
        )}
      {children}
    </ToastContext.Provider>
  );
};
