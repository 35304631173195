import { EventCategory, EventName, useAnalytics } from './useAnalytics';

export const useFavouritesAnalytics = () => {
  const { sendEvent, sendErrorEvent } = useAnalytics();

  const sendCreateListEvent = (e: any, newListId: string) => {
    sendEvent({
      event_action: EventName.CREATE_LIST,
      event_category: EventCategory.ECOMMERCE,
      event_label: e.source,
      custom: {
        list_id: newListId,
      },
      non_interaction: false,
    });
  };

  const sendAddToWishlistEvent = (e: any, listId: string) => {
    sendEvent({
      event_action: EventName.ADD_TO_WISHLIST,
      event_category: EventCategory.ECOMMERCE,
      event_label: e.source,
      custom: {
        list_id: listId,
        item_number_event: e.productNumber,
        item_type_event: e.productType,
        product_quantity: e.quantity?.toString(),
      },
      non_interaction: false,
    });
  };

  const sendRemoveFromWishlistEvent = (e: any, listId: string) => {
    sendEvent({
      event_action: EventName.REMOVE_FROM_WISHLIST,
      event_category: EventCategory.ECOMMERCE,
      event_label: e.source,
      custom: {
        list_id: listId,
        item_number_event: e.productNumber,
        item_type_event: e.productType,
        product_quantity: e.quantity && `-${e.quantity}`,
      },
      non_interaction: false,
    });
  };

  return {
    sendErrorEvent,
    sendCreateListEvent,
    sendAddToWishlistEvent,
    sendRemoveFromWishlistEvent,
  };
};
