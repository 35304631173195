import './utils/loadCookieConsent';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { Actions } from './actions/enums';
import App from './App';
import { IdentityProvider } from './providers/IdentityProvider';
import { ToastProvider } from './providers/ToastProvider';
import { TranslationsProvider } from './providers/TranslationsProvider';

const container = document.createElement('div');
container.id = 'mv-agent-root';
document.body.appendChild(container);
const root = createRoot(container);

if (process.env.NODE_ENV === 'development' && !window.ikea.pubsub) {
  let listeners: { msg: Actions; cb: () => {} }[] = [];

  if (!window.ikea) {
    // @ts-expect-error It complains about us not setting pubsub, we do this the line after
    window.ikea = {};
  }

  window.ikea.pubsub = {
    subscribe: (msg: Actions, cb: () => {}) => listeners.push({ msg, cb }),
    unsubscribe: (targetMsg: Actions, targetCb: () => {}) => {
      listeners = listeners.filter(({ msg, cb }) => msg !== targetMsg || cb !== targetCb);
    },
    publish: (targetMsg: Actions) => {
      for (const entry of listeners) {
        if (entry.msg === targetMsg) {
          entry.cb();
        }
      }
    },
  };
}

root.render(
  <StrictMode>
    <TranslationsProvider>
      <IdentityProvider>
        <ToastProvider>
          <App />
        </ToastProvider>
      </IdentityProvider>
    </TranslationsProvider>
  </StrictMode>,
);

window.ikea.mvAgent = window.ikea.mvAgent ?? {};
window.ikea.mvAgent.useSideMenu = () => true;

if (window.ikea.mvAgent.useSideMenu()) {
  document.getElementById('hnf-header-profile')?.addEventListener?.('click', (e) => {
    e?.preventDefault?.();
    window.ikea?.pubsub?.publish?.(Actions.PROFILE_OPEN_MENU);
  });
}
