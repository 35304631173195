import { t } from 'i18next';
import Cookies from 'js-cookie';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Actions, StorageState } from '../actions/enums';
import { createList } from '../network/endpoints/favourites/createList';
import { deleteItemFromList } from '../network/endpoints/favourites/deleteItemFromList';
import { getItemMetadataCompact } from '../network/endpoints/favourites/getItemMetadataCompact';
import { getLists } from '../network/endpoints/favourites/getLists';
import { postItem } from '../network/endpoints/favourites/postItem';
import { postUserReplacement } from '../network/endpoints/favourites/postUserReplacement';
import { Cookie, Storage } from '../network/enums';
import { ErrorType, List } from '../network/types';
import { getCookieDomain } from '../network/utils/getCookieDomain';
import { getRetailUnitAndLanguage } from '../network/utils/getRetailUnitAndLanguage';
import { getRetailUnitCookiePath } from '../network/utils/getRetailUnitCookiePath';
import { goToFavouritesList } from '../network/utils/goToFavouritesList';
import { IdentityContext, SessionState } from '../providers/IdentityProvider';
import { ItemsImages, getItemImagesFromLists } from '../utils/getItemImagesFromLists';
import { useFavouritesAnalytics } from './useFavouritesAnalytics';
import { useToast } from './useToast';

type ListImage = {
  altText: string;
  src: string;
  srcSet?: string;
};

type ListAddItem = {
  id: string;
  name: string;
  image?: ListImage;
};

type ListDeleteItem = {
  id: string;
  name: string;
  image?: ListImage;
  itemIds: string[];
};

type RemovedLists = {
  name: string;
};

type TRemoveFromFavouritesPayload = {
  productName: string;
  productNumber: string;
  productType?: string;
  listId?: string;
  source: string;
};

export type TRemoveFromFavouritesPayloadResponse = {
  productName: string;
  productNumber: string;
  productType: string;
  listIdsRemoveItem?: string[];
  addedItemId?: string;
  listId?: string;
  listName?: string;
  skipToast?: boolean;
  keepIcon?: boolean;
};

export type TRemoveFromFavouritesPayloadResponseError = {
  productName: string;
  productNumber: string;
  productType?: string;
  skipToast?: boolean;
  listId?: string;
  listName?: string;
};

type TAddToFavouritesPayload = {
  productNumber: string;
  productType: string;
  quantity: string | number;
  productName: string;
  listId?: string;
  source: string;
};

export type TAddToFavouritesPayloadResponse = {
  productNumber: string;
  productType: string;
  quantity: number;
  productName: string;
  listId: string;
  listName?: string;
  itemId: string;
  source: string;
};

export type TAddToFavouritesPayloadResponseError = {
  productNumber: string;
  productType: string;
  quantity: number;
  productName: string;
  listId?: string;
  listName?: string;
  skipToast?: boolean;
  source: string;
};

interface DefaultList {
  id: string;
  name: string;
}

let lastItemCookieValue = '';

export const useFavourites = () => {
  const toast = useToast();
  const { sessionState, getSession } = useContext(IdentityContext);
  const { sendAddToWishlistEvent, sendRemoveFromWishlistEvent } = useFavouritesAnalytics();

  const [itemToAdd, setItemToAdd] = useState<TAddToFavouritesPayload | undefined>();
  const [listsToAddItem, setListsToAddItem] = useState<ListAddItem[]>([]);
  const [showAddItemToListModal, setShowAddItemToListModal] = useState(false);

  const [itemToRemove, setItemToRemove] = useState<TRemoveFromFavouritesPayload | undefined>();
  const [listsToRemoveItem, setListsToRemoveItem] = useState<ListDeleteItem[]>([]);
  const [removedLists, setRemovedLists] = useState<RemovedLists[]>([]);
  const [showAddOrRemoveModal, setShowAddOrRemoveModal] = useState(false);

  const [fetchItemsState, setFetchItemsState] = useState(false);
  const [showRemovedListsModal, setShowRemovedListsModal] = useState(false);
  const [favouritesAgentLoaded, setFavouritesAgentLoaded] = useState(false);

  const blockTryToRemoveFromFavourites = useRef(false);

  const favouritesItemState = useMemo(
    () => `${Storage.FAVOURITES_ITEM_STATE}_${getRetailUnitAndLanguage().retailUnit}`,
    [],
  );

  const getLists = useGetLists();

  /**
   * Fetch all items from list to update item state in cookie if cookie doesn't exist.
   */
  useEffect(() => {
    if (sessionState === SessionState.DONE) {
      (async () => {
        if (window.localStorage?.getItem(favouritesItemState) === undefined && !fetchItemsState) {
          setFetchItemsState(true);
          const lists = await getLists(true);
          const allItemsNumbers = [
            ...Array.from(new Set(lists.flatMap((list) => (list.items ? list.items.map((item) => item.number) : [])))),
          ];
          window.localStorage?.setItem(favouritesItemState, encodeItemStorageValue(allItemsNumbers));
        }
      })();
    }
  }, [favouritesItemState, fetchItemsState, sessionState, getLists]);

  /**
   * Publish FAVOURITES_UPDATED event when items state changes
   */
  useEffect(() => {
    if (lastItemCookieValue !== window.localStorage?.getItem(favouritesItemState)) {
      window.ikea.pubsub.publish(Actions.FAVOURITES_UPDATED, {
        favouritedItems: decodeItemStorageValue(window.localStorage?.getItem(favouritesItemState)),
      });
      lastItemCookieValue = window.localStorage?.getItem(favouritesItemState) ?? '';
    }
  });

  const decodeItemStorageValue = (state: string | null) =>
    decodeURIComponent(state ?? '')
      .split(',')
      .filter((item) => !!item);

  const encodeItemStorageValue = (items: string[]) => encodeURIComponent(items.join(','));

  const addItemToState = useCallback(
    (itemNo: string): void => {
      const items = decodeItemStorageValue(window.localStorage?.getItem(favouritesItemState));
      if (items.includes(itemNo)) return;
      items.push(itemNo);
      window.localStorage?.setItem(favouritesItemState, encodeItemStorageValue(items));
    },
    [favouritesItemState],
  );

  const removeItemFromState = useCallback(
    (itemNo: string): void => {
      const items = decodeItemStorageValue(window.localStorage?.getItem(favouritesItemState));
      const index = items.findIndex((value) => value === itemNo);
      if (index === -1) return;
      items.splice(index, 1);
      window.localStorage?.setItem(favouritesItemState, encodeItemStorageValue(items));
    },
    [favouritesItemState],
  );

  const resetAddItem = () => {
    setItemToAdd(undefined);
    setListsToAddItem([]);
  };
  const resetRemoveItem = () => {
    setItemToRemove(undefined);
    setListsToRemoveItem([]);
  };

  /* Success and Error Responses */
  const addToFavouritesSuccess = useCallback(
    async (e: TAddToFavouritesPayloadResponse, skipLink = false) => {
      window.ikea.pubsub.publish(Actions.ADD_TO_FAVOURITES_SUCCESS, e);
      sendAddToWishlistEvent(e, e.listId);

      const lists = await getLists(true);
      const selectedList = lists.find((x) => x.id === e.listId);

      let text;

      if (e.source === 'cart') {
        text =
          lists.length > 1
            ? t('fa.mvAgent.toast.moveToFav.selectedList.label', {
                item: e.productName,
                list: selectedList?.name,
              })
            : t('fa.mvAgent.toast.moveToFav.label', { item: e.productName });
      } else {
        text = t('fa.toast.addToFavourites.label', { item: e.productName });
      }

      toast.set({
        text,
        ...(!skipLink && {
          actionButtonText: t('fa.toast.button.view.label'),
          ariaLabelCloseBtn: t('fa.toast.button.view.label'),
          actionClick: () => goToFavouritesList(e.listId),
        }),
      });
      addItemToState(e.productNumber);
      resetAddItem();
      resetRemoveItem();
    },
    [addItemToState, getLists, sendAddToWishlistEvent, toast],
  );

  const addToFavouritesFail = useCallback(
    (error: any, e: TAddToFavouritesPayloadResponseError) => {
      window.ikea.pubsub.publish(Actions.ADD_TO_FAVOURITES_FAIL, {
        errorMessage: error.message,
        ...e,
      });

      let text;

      if (!e.skipToast) {
        if (error.type === ErrorType.INVALID_QUANTITY) {
          text = t('fa.toast.maxQuantityError.text');
        } else if (e.source === 'cart') {
          text = t('fa.mvAgent.toast.moveToFavError.unsuccessful.label', { item: e.productName });
        } else {
          text = t('fa.toast.addToFavourites.errorLabel', { item: e.productName });
        }

        toast.set({
          text,
        });
      }
      resetAddItem();
      resetRemoveItem();
    },
    [toast],
  );

  const removeFromFavouritesSuccess = useCallback(
    (e: TRemoveFromFavouritesPayloadResponse) => {
      window.ikea.pubsub.publish(Actions.REMOVE_FROM_FAVOURITES_SUCCESS, e);

      e.listIdsRemoveItem?.forEach((listId) => {
        sendRemoveFromWishlistEvent(e, listId);
      });

      if (!e.keepIcon) {
        removeItemFromState(e.productNumber);
      }

      resetAddItem();
      resetRemoveItem();
      toast.set({
        text: e.listName
          ? t('fa.toast.removeItemFromList.label', {
              item: e.productName,
              list: e.listName,
            })
          : t('fa.toast.removeItemFromLists.label', {
              item: e.productName,
            }),
      });
    },
    [removeItemFromState, sendRemoveFromWishlistEvent, toast],
  );

  const removeFromFavouritesFail = useCallback((error: any, e: TRemoveFromFavouritesPayloadResponseError) => {
    window.ikea.pubsub.publish(Actions.REMOVE_FROM_FAVOURITES_FAIL, {
      errorMessage: error.message,
      ...e,
    });
    resetAddItem();
    resetRemoveItem();
  }, []);

  /* Sub functions */
  const addToFavourites = useCallback(
    async (listId: string, e: TAddToFavouritesPayload, listName?: string, skipLink = false) => {
      const response = await postItem(listId, {
        itemNumber: e.productNumber,
        itemType: e.productType,
        quantity: parseInt(e.quantity as string, 10),
      });
      const payload: TAddToFavouritesPayloadResponse = {
        ...e,
        quantity: parseInt(e.quantity as string, 10),
        listId,
        itemId: response.results.item.id,
      };
      if (listName) payload.listName = listName;
      await addToFavouritesSuccess(payload, skipLink);
    },
    [addToFavouritesSuccess],
  );

  const handleAddItem = (itemImage: ItemsImages | undefined, defaultList: DefaultList) => {
    setListsToAddItem((prevState) => [
      ...prevState,
      !itemImage ? defaultList : { ...defaultList, image: itemImage.image },
    ]);
  };

  const handleRemoveItem = (itemImage: ItemsImages | undefined, itemIds: string[], defaultList: DefaultList) => {
    const defaultListToRemove = { ...defaultList, itemIds: itemIds };
    setListsToRemoveItem((prevState) => [
      ...prevState,
      !itemImage ? defaultListToRemove : { ...defaultListToRemove, image: itemImage.image },
    ]);
  };

  const addOrRemoveItem = useCallback(async (e: TRemoveFromFavouritesPayload, listsResults: List[]) => {
    setItemToRemove(e);
    setItemToAdd({
      ...e,
      quantity: 1,
    } as TAddToFavouritesPayload);
    const itemsImages = await getItemImagesFromLists(listsResults);
    listsResults.forEach((list) => {
      const itemIds: string[] = [];
      if (list.items) {
        list.items?.forEach((item) => {
          if (item.number === e.productNumber) {
            itemIds.push(item.id);
          }
        });
      }
      const defaultList: DefaultList = { id: list.id, name: list.name };
      const itemImage = itemsImages.find((item) => list.items?.length && item.itemNumber === list.items[0].number);
      if (itemIds.length > 0) {
        handleRemoveItem(itemImage, itemIds, defaultList);
      } else {
        handleAddItem(itemImage, defaultList);
      }
    });
    setShowAddOrRemoveModal(true);
  }, []);

  const removeFromList = useCallback(
    async (e: TRemoveFromFavouritesPayload, list: List) => {
      const items = list?.items ?? [];
      await Promise.all(
        items.map((item) => {
          if (e.productNumber === item.number && e.productType === item.type)
            return deleteItemFromList(list.id, item.id);
        }),
      );
      removeFromFavouritesSuccess({
        ...e,
        listIdsRemoveItem: [list.id],
        listName: list.name,
      } as TRemoveFromFavouritesPayloadResponse);
    },
    [removeFromFavouritesSuccess],
  );

  const getProductType = useCallback(async (e: TRemoveFromFavouritesPayload) => {
    const productType = e.productType ?? (await getItemMetadataCompact(e.productNumber)).results[0].type;
    if (!productType) {
      throw new Error('Product type not found');
    }
    return productType;
  }, []);

  /* Main functions */
  const tryToAddToFavourites = useCallback(
    async (e: TAddToFavouritesPayload) => {
      window.ikea.pubsub.publish(Actions.ADD_TO_FAVOURITES_INITIATE, e);
      try {
        if (e.listId) {
          await addToFavourites(e.listId, e, undefined, true);
          return;
        }
        const simpleListsResponse = await getLists(true);
        if (simpleListsResponse.length === 0) {
          const { id, name } = (await createList(t('fa.mvAgent.createList.defaultNewListName.text') ?? undefined))
            .results;
          await addToFavourites(id, e, name);
          return;
        }
        if (simpleListsResponse.length === 1) {
          const [list] = simpleListsResponse;
          const { id } = list;
          await addToFavourites(id, e);
          return;
        }
        setItemToAdd(e);
        const itemsImages = await getItemImagesFromLists(simpleListsResponse);
        setListsToAddItem(
          simpleListsResponse.map((list) => {
            const defaultList = { id: list.id, name: list.name };
            const itemImage = itemsImages.find((item) => list.items && item.itemNumber === list.items[0]?.number);
            if (!itemImage) return defaultList;
            return { id: list.id, name: list.name, image: itemImage.image };
          }),
        );
        setShowAddItemToListModal(true);
      } catch (error: any) {
        addToFavouritesFail(error, { ...e, quantity: parseInt(e.quantity as string, 10) });
      }
    },
    [addToFavourites, addToFavouritesFail, getLists],
  );

  const tryToRemoveFromFavourites = useCallback(
    async (e: TRemoveFromFavouritesPayload) => {
      if (blockTryToRemoveFromFavourites.current) {
        return;
      }
      blockTryToRemoveFromFavourites.current = true;
      window.ikea.pubsub.publish(Actions.REMOVE_FROM_FAVOURITES_INITIATE, e);
      try {
        const [lists, productPayload] = await Promise.all([
          getLists(true),
          getProductType(e).then((productType) => ({ ...e, productType })),
        ]);
        if (lists.length < 1) {
          throw new Error(
            `There are no lists with given item: ${productPayload.productType}-${productPayload.productName}`,
          );
        }
        if (lists.length === 1) {
          await removeFromList(productPayload, lists[0]);
          return;
        }
        await addOrRemoveItem(productPayload, lists);
      } catch (error: any) {
        removeFromFavouritesFail(error, e);
      }
      blockTryToRemoveFromFavourites.current = false;
    },
    [addOrRemoveItem, getProductType, removeFromFavouritesFail, removeFromList, getLists],
  );

  /* Listners functions */
  const addToFavouritesListenerCallback = useCallback(
    async (e: TAddToFavouritesPayload) => {
      await getSession();
      await tryToAddToFavourites(e);
    },
    [getSession, tryToAddToFavourites],
  );

  const removeFromFavouritesListenerCallback = useCallback(
    async (e: TRemoveFromFavouritesPayload) => {
      await getSession();
      await tryToRemoveFromFavourites(e);
    },
    [getSession, tryToRemoveFromFavourites],
  );

  const listenForAddToFavourites = useCallback(() => {
    window.ikea.pubsub.subscribe(Actions.ADD_TO_FAVOURITES_ACTION, addToFavouritesListenerCallback);
  }, [addToFavouritesListenerCallback]);

  const listenForRemoveFromFavourites = useCallback(() => {
    window.ikea.pubsub.subscribe(Actions.REMOVE_FROM_FAVOURITES_ACTION, removeFromFavouritesListenerCallback);
  }, [removeFromFavouritesListenerCallback]);

  const userLoginCallback = useCallback(async () => {
    try {
      await getSession(true);
      const userId = Cookies.get(Cookie.FAVOURITES_GUEST_ID);
      const userProvider = Cookies.get(Cookie.FAVOURITES_GUEST_PROVIDER);
      if (userId && userProvider) {
        const response = await postUserReplacement(userId, userProvider);
        const removedLists = response.results.deletedLists;
        const hasRemovedLists = removedLists?.length > 0;
        Cookies.remove(Cookie.FAVOURITES_GUEST_ID, { domain: getCookieDomain, path: getRetailUnitCookiePath });
        Cookies.remove(Cookie.FAVOURITES_GUEST_PROVIDER, { domain: getCookieDomain, path: getRetailUnitCookiePath });
        window.localStorage?.removeItem(favouritesItemState);
        lastItemCookieValue = '';
        setFetchItemsState(false);
        setRemovedLists(removedLists);
        setShowRemovedListsModal(hasRemovedLists);
        if (hasRemovedLists) {
          sessionStorage.setItem(StorageState.SHOW_FAVOURITES_MERGE_EXCEEDED_MAX_AMOUNT_PROMPT, 'true');
        }
      }
      window.ikea.pubsub.publish(Actions.USER_LOGGED_IN_AGENT_POST_FAVOURITES_WORK_SUCCESS, null);
    } catch (error) {
      window.ikea.pubsub.publish(Actions.USER_LOGGED_IN_AGENT_POST_FAVOURITES_WORK_FAIL, null);
    }
  }, [favouritesItemState, getSession]);

  const listenForUserLogin = useCallback(() => {
    window.ikea.pubsub.subscribe(Actions.USER_LOGGED_IN, userLoginCallback);
  }, [userLoginCallback]);

  const userLogoutCallback = useCallback(async () => {
    try {
      Cookies.remove(Cookie.USER_LOGGEDIN, { path: getRetailUnitCookiePath, domain: getCookieDomain });
      window.localStorage?.removeItem(favouritesItemState);
      lastItemCookieValue = '';
      window.ikea.pubsub.publish(Actions.USER_LOGGED_OUT_AGENT_POST_FAVOURITES_WORK_SUCCESS, null);
    } catch (error) {
      window.ikea.pubsub.publish(Actions.USER_LOGGED_OUT_AGENT_POST_FAVOURITES_WORK_FAIL, null);
    }
  }, [favouritesItemState]);

  const listenForUserLogout = useCallback(() => {
    window.ikea.pubsub.subscribe(Actions.USER_LOGGED_OUT, userLogoutCallback);
  }, [userLogoutCallback]);

  const execSubscribeListeners = useCallback(() => {
    if (!window?.ikea && !window.ikea?.pubsub) {
      return;
    }
    listenForAddToFavourites();
    listenForRemoveFromFavourites();
    listenForUserLogin();
    listenForUserLogout();
    window.ikea.mvAgent = window.ikea.mvAgent || {};
    const favouritesApi = {
      removeItemFromState,
      agentLoaded: true,
    };
    window.ikea.favourites = favouritesApi;
    window.ikea.mvAgent.favourites = favouritesApi;
    if (!favouritesAgentLoaded) {
      setFavouritesAgentLoaded(true);
      document.body.dispatchEvent(new Event(Actions.FAVOURITES_AGENT_LOADED, { bubbles: true, cancelable: true }));
      window.ikea.pubsub.publish(Actions.FAVOURITES_AGENT_LOADED, null);
    }
  }, [
    favouritesAgentLoaded,
    listenForAddToFavourites,
    listenForRemoveFromFavourites,
    listenForUserLogin,
    listenForUserLogout,
    removeItemFromState,
  ]);

  const execUnsubscribeListeners = useCallback(() => {
    if (!window?.ikea && !window.ikea?.pubsub) {
      return;
    }
    window.ikea.pubsub.unsubscribe(Actions.ADD_TO_FAVOURITES_ACTION, addToFavouritesListenerCallback);
    window.ikea.pubsub.unsubscribe(Actions.REMOVE_FROM_FAVOURITES_ACTION, removeFromFavouritesListenerCallback);
    window.ikea.pubsub.unsubscribe(Actions.USER_LOGGED_IN, userLoginCallback);
    window.ikea.pubsub.unsubscribe(Actions.USER_LOGGED_OUT, userLogoutCallback);
  }, [addToFavouritesListenerCallback, removeFromFavouritesListenerCallback, userLoginCallback, userLogoutCallback]);

  const mvFavouritesAgent = useCallback(async () => {
    if (document.readyState === 'complete' || window.ikea.pubsub) {
      execSubscribeListeners();
    } else {
      window.addEventListener('load', execSubscribeListeners);
    }
  }, [execSubscribeListeners]);

  const unsubscribePubsub = useCallback(() => {
    if (document.readyState === 'complete' || window.ikea.pubsub) {
      execUnsubscribeListeners();
    } else {
      window.addEventListener('load', execUnsubscribeListeners);
    }
  }, [execUnsubscribeListeners]);

  useEffect(() => {
    mvFavouritesAgent();
    return unsubscribePubsub;
  }, [mvFavouritesAgent, unsubscribePubsub]);

  return {
    listsToAddItem,
    itemToAdd,
    showAddItemToListModal,
    setShowAddItemToListModal,
    addToFavouritesSuccess,
    addToFavouritesFail,
    itemToRemove,
    listsToRemoveItem,
    removedLists,
    setShowRemovedListsModal,
    showAddOrRemoveModal,
    showRemovedListsModal,
    setShowAddOrRemoveModal,
    removeFromFavouritesSuccess,
    removeFromFavouritesFail,
    removeFromList,
    getLists,
  };
};

/**
 * Returns a cached promise for getLists
 */
function useGetLists() {
  const [lists, setLists] = useState<ReturnType<typeof getLists>>();
  const { getSession } = useContext(IdentityContext);

  return useCallback(
    (refetch = false) => {
      if (!lists || refetch) {
        const newLists = getSession().then(() => getLists());
        setLists(newLists);
        return newLists;
      }
      return lists;
    },
    [getSession, lists],
  );
}
