export enum RequestTimeout {
  XXS = 500,
  XS = 1000,
  S = 2000,
  M = 4000,
  L = 8000,
  XL = 16000,
  XXL = 32000,
}

export interface Error {
  name: string;
  message: string;
  code: string;
}

export interface Provider {
  status: number;
  payload?: any;
  error?: Error;
}

export type ListItem = {
  id: string;
  number: string;
  type: string;
  quantity: number;
};

export type List = {
  id: string;
  name: string;
  items?: ListItem[];
};

export type FetchListsResponse = {
  results: List[];
};

export type CreateListResponse = {
  results: {
    id: string;
    name: string;
  };
};

export type ItemMetadataCompact = {
  number: string;
  type: string;
  media: {
    id: string;
    number: string;
    name: string;
    altText: string;
    variants: { quality: string; href: string }[];
  }[];
};

export type GetItemMetadataCompactResponse = {
  results: ItemMetadataCompact[];
};

export type GenericResponse = {
  results: any;
};

export type PostItemBody = {
  itemType: string;
  quantity: number;
  itemNumber: string;
};

export type PostItemResponse = {
  results: {
    id: string;
    item: {
      id: string;
      number: string;
      type: string;
      quantity: number;
    };
  };
};

export type CartUserReplacementResponse = {
  totalLineQuantities: number;
  mergedLines: {
    lineId: string;
    itemNumber: string;
    itemType: string;
    previousQuantity: number;
    requestedQuantity: number;
    actualQuantity: number;
  }[];
};

export type FavouritesUserReplacementResponse = {
  results: {
    deletedLists: [{ name: string }];
  };
};

export type GetTranslations = {
  [key: string]: any;
};

export type GetTranslationsResponse = {
  results: GetTranslations;
};

export enum Type {
  GUEST = 'guest',
  REGISTERED = 'registered',
}

export type User = {
  type: Type;
  id: string;
  provider: string;
  name?: string;
  isLoggedIn: boolean;
  custom: {
    [key: string]: any;
  };
};

export type GetSessionResponse = {
  results: User;
};

export type TCartItem = {
  productNumber: string;
  productType: string;
  productName?: string;
  productPrice: number;
  quantity: number | string;
  source: string;
  currency: string;
  ratings: number;
  itemId?: string;
};

export type TAddToCartPayload = {
  source: string;
  items: TCartItem[];
  label?: string;
  skipToast?: boolean;
  isUndoAction?: boolean;
};

export enum ErrorType {
  ITEM_DOES_NOT_EXIST = 'ITEM_DOES_NOT_EXIST',
  INVALID_QUANTITY = 'INVALID_QUANTITY',
  TRANSLATIONS_NOT_FOUND = 'TRANSLATIONS_NOT_FOUND',
  TRANSLATIONS_FAILED_TO_LOAD = 'TRANSLATIONS_FAILED_TO_LOAD',
}
