import './App.scss';

import { useContext, useEffect } from 'react';

import { LoginModal } from './components/Modals/LoginModal/LoginModal';
import { MvAgentCart } from './components/MvAgentCart/MvAgentCart';
import { MvAgentFavourites } from './components/MvAgentFavourites/MvAgentFavourites';

import { setupSession } from './network/axios';
import { TranslationsContext, TranslationsState } from './providers/TranslationsProvider';

declare global {
  interface Window {
    ikea: Record<Exclude<string, 'mvAgent'>, any> & {
      mvAgent: Record<Exclude<string, 'isUserLoggedIn'>, any> & {
        isUserLoggedIn: () => Promise<boolean>;
      };
    };
    sendEvent?: any;
  }
}

const App = () => {
  const { translationsState, getTranslations } = useContext(TranslationsContext);

  useEffect(() => {
    getTranslations();
  }, [getTranslations]);

  useEffect(() => {
    window.ikea.mvAgent = window.ikea.mvAgent || {};
    window.ikea.mvAgent.isUserLoggedIn = async () => (await setupSession()).isLoggedIn;
  }, []);

  return (
    <>
      {translationsState === TranslationsState.DONE && (
        <>
          <MvAgentCart />
          <MvAgentFavourites />
          <LoginModal />
        </>
      )}
    </>
  );
};

export default App;
